import * as React from "react"
import Page from "../components/page";
import image2 from "./new-groups-page.png"
import image3 from "./new-mobile-directory.png"
import {HomePageSignUp} from "../components/home-page-sign-up";
import {AnchorLink} from "../components/menu";
import {BlockHero} from "../blocks/hero";
import {BlockHowItWorks} from "../blocks/how-it-works";
import {BlockFeatures} from "../blocks/features";
import {BlockFuture} from "../blocks/future";
import {BlockCTA} from "../blocks/cta";
import {BlockTestimonial} from "../blocks/testimonial";
// styles

//
const IndexPage = () => {

    const hero = {
        //title: "The Toolkit for Tomorrow’s Leaders",
        title: "Member relationship & messaging magic",
        subtitle: "Unaty is a simpler, smarter member relationship management and messaging tool made for Community Leaders.",
        actions: [
            {
                text: "Sign in",
                link: "https://getunaty.com/sign-in"
            }
        ],
      //  tag: "Introducing Unaty",
        image: image2,
    };

    //   <BlockCTA />
    // <BlockHowItWorks />
    // <BlockFeatures />
    //

    return (
        <Page color='' title="Unaty: The Community Organizing Platform" menu>
            <BlockHero {...hero} />
            {/*<BlockTestimonial />*/}

            {/* <BlockFuture />*/}

        </Page>
    )
};

export default IndexPage
